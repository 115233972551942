<template>
  <div
    v-if="typeof data.amount === 'number' || typeof data.total === 'number'"
    class="payment-selector-list-item"
  >
    <div>
      <p>{{ data.date ? formatDate(data.date) : data.due_date ? formatDate(data.due_date) : '' }}</p>
      <p class="description">{{ data.description ? data.description : '' }}</p>
    </div>
    <p
      :class="{ off: data.discount > 0 }"
      class="money"
    >{{ data.amount ? formatMoney(data.amount) : '' }}</p>
    <p v-if="data.discount > 0" class="money">{{ data.total ? formatMoney(data.total) : '' }}</p>
    <el-button
      v-if="action && data.description && ($route.name === 'Detalhes do recebimento' || $route.name === 'Detalhes do reparcelamento')"
      :disabled="!reverse && data.selected || totalRemaining <= 0 || data.amount <= 0 || $route.fullPath.includes('recebimento-boleto')"
      type="primary"
      class="selector-button-amount"
      @click="$emit('onClick', data)"
    >{{ reverse ? '-' : future ? 'Antecipar' : '+' }}</el-button>
  </div>
</template>

<script>
import moment from "moment";
import toMoney from "@/helpers/toMoney";

export default {
  props: {
    data: {
      type: [Object, null],
      default: () => {}
    },
    action: {
      type: [Boolean, null],
      default: false
    },
    future: {
      type: [Boolean, null],
      default: false
    },
    reverse: {
      type: [Boolean, null],
      default: false
    },
    totalRemaining: {
      type: [Number, null],
      default: () => {}
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    formatMoney(amount) {
      return toMoney(amount)
        .replace("R$", "")
        .replace(/\s/g, "");
    }
  }
};
</script>


<style lang="scss">
.payment-selector-list-item {
  &:nth-child(odd) {
    background: rgba(108, 115, 124, 0.1);
  }
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 64px 3px 0;
  p {
    font-family: Montserrat;
    font-weight: 400;
    color: $preto;
    font-size: 13px;
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 14px;
    p:first-child {
      padding: 0 4px;
      min-width: 90px;
    }
    p + p {
      margin-left: 4px;
    }
  }
  .selector-button-amount {
    top: 2px !important;
    @media screen and (max-width: 320px) {
      top: 25px !important;
    }
  }
  .money {
    font-size: 13px;
    font-weight: 700;
    text-align: right;
    font-family: "Roboto Mono", monospace;
    @media screen and (max-width: 320px) {
      position: absolute;
      right: 0;
    }
  }
  .off {
    font-size: 11px;
    vertical-align: middle;
    padding: 4px 4px 0 0;
    color: $preto;
    text-decoration: line-through;
    opacity: 0.4;
  }
  .description {
    width: 100%;
  }
  @media screen and (max-width: 320px) {
    flex-wrap: wrap;
  }
}
</style>
