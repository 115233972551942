<template>
  <div class="payment-selector">
    <previous-statement :data="data" :future="future" v-if="data.status === 'closed'" />
    <div style="margin-top: 1rem;" class="payment-title">
      <h4 v-if="data.items && data.items.length > 0">ITENS</h4>
      <payment-selector-list
        v-if="data.items && data.items.length > 0"
        :statement-id="data.id"
        :data="data.items"
        :total="data.total_statement_amount"
        :total-remaining="data.total_remaining_amount"
        :action="action"
        :future="future"
        :future-item="futureItem"
        :all="selectedAll"
        :deselected-item="deselectedItem"
        :previous-condition="data.previous_condition"
        :status="data.status"
        @onClick="handleListClick"
        @disableAll="data => disableAll = data"
      />
    </div>
    <h4 v-if="data.payments && data.payments.length > 0">PAGAMENTOS</h4>
    <payment-selector-list
      v-if="data.payments && data.payments.length > 0"
      :data="data.payments"
      :hide-total="true"
    />
    <div class="statement-total">
      <h4>
        TOTAL RESTANTE
        <strong>{{ formatTotal(data.total_remaining_amount) }}</strong>
      </h4>
      <el-button
        v-if="action && !future && ($route.name === 'Detalhes do recebimento' || $route.name === 'Detalhes do reparcelamento')"
        :disabled="disableAll || data.total_remaining_amount <= 0"
        type="primary"
        class="selector-button-amount"
        @click="handleAll"
      >{{ future ? 'Antecipar' : '+' }}</el-button>
    </div>
    <payment-selector-list-modal
      v-if="$route.name === 'Detalhes do recebimento'"
      :active="showModal"
      :data="selectedInstallment"
      @onClose="handleModalClose"
      @onSubmit="handleModalSubmit"
    />
  </div>
</template>

<script>
import moment from "moment";
import toMoney from "@/helpers/toMoney";

import PreviousStatement from "./PreviousStatement";
import PaymentSelectorList from "./PaymentSelectorList";
import PaymentSelectorListModal from "./PaymentSelectorListModal";

export default {
  components: {
    PreviousStatement,
    PaymentSelectorList,
    PaymentSelectorListModal
  },
  props: {
    data: {
      type: [Object, null],
      default: () => {}
    },
    future: {
      type: [Boolean, null],
      default: false
    },
    deselectedItem: {
      type: [Object, null],
      default: () => null
    },
    action: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedAll: false,
      disableAll: false,
      showModal: false,
      futureItem: null,
      interestItem: null,
      selectedInstallment: []
    };
  },
  watch: {
    deselectedItem: function(newValue) {
      if (!newValue.id) {
        this.selectedAll = false;
        this.disableAll = false;
      }
    }
  },
  methods: {
    formatTotal(amount) {
      if (amount <= 0) {
        return this.formatMoney(0);
      }
      return this.formatMoney(amount);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    handleAll() {
      this.selectedAll = true;
      this.disableAll = true;
      this.$emit("onClickAll", this.data);
    },
    handleListClick(data) {
      if (this.future) {
        this.selectedInstallment = data;
        return (this.showModal = true);
      }
      this.$emit("onClick", data);
    },
    handleModalClose() {
      this.selectedInstallment = [];
      this.selectedAll = false;
      this.disableAll = false;
      return (this.showModal = false);
    },
    handleModalSubmit(data) {
      this.futureItem = data;
      this.$emit("onClick", data);
    }
  }
};
</script>

<style lang="scss">
.payment-selector {
  h4 {
    font-size: 13px;
    font-family: Montserrat;
    color: $preto;
    font-weight: 700;
    letter-spacing: 1.025px;
    margin: 12px 0;
    padding: 0 4px;
  }
  .payment-title {
    width: 100%;
    background-color: #f6f6f6;
    position: relative;
  }
  .statement-total {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    padding-right: 56.6px;
    h4 {
      font-size: 13px;
      strong {
        font-family: "Roboto Mono", monospace;
        margin-left: 30px;
        color: $laranja;
        font-size: 13px;
      }
    }
    .selector-button-amount {
      top: 14.6px;
    }
  }
}
</style>
