<template>
  <el-dialog
    :visible.sync="visible"
    class="payment-selector-modal"
    title="ANTECIPAÇÃO"
    @close="$emit('onClose'); discount = false;"
  >
    <h4>Antecipar a(s) parcela(s)</h4>
    <payment-selector-list :data="data" :hide-total="true" />
    <div class="amount-input">
      <div
        class="custom-switch"
        style="display: flex; justify-content: space-around; align-items: center; margin-top: 1rem;"
      >
        <span>Desconto por antecipação da parcela</span>
        <el-switch v-model="discount" active-text="Sim" inactive-text="Não"></el-switch>
      </div>
      <h5 v-if="discount">Desconto de</h5>
      <money
        v-if="discount"
        :disabled="auth_details && auth_details.permissions && !auth_details.permissions.installment_discount"
        ref="input"
        v-model="interest"
        v-bind="$money"
        class="el-input__inner"
      />
    </div>
    <el-alert
      v-if="data[0] ? interest > data[0].amount : false"
      :closable="false"
      title="O desconto não pode ser maior que o valor da parcela"
      type="warning"
    />
    <div class="button-wrapper">
      <el-button round size="mini" @click="handleCancel">Cancelar</el-button>
      <el-button
        :disabled="data[0] ? interest > data[0].amount : false"
        type="primary"
        round
        size="mini"
        @click="handleSubmit"
      >Confirmar</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Money } from "v-money";
import PaymentSelectorList from "./PaymentSelectorList";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  components: {
    Money,
    PaymentSelectorList
  },
  props: {
    data: {
      type: [Array, null],
      default: () => []
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    })
  },
  data() {
    return {
      visible: false,
      interest: 0,
      discount: false
    };
  },
  watch: {
    active: function(newValue) {
      this.visible = newValue;
    }
  },
  methods: {
    handleCancel() {
      this.interest = 0;
      this.visible = false;
    },
    handleSubmit() {
      this.$emit("onSubmit", {
        ...this.data[0],
        discount: this.interest,
        total: this.data[0].amount - this.interest
      });
      this.interest = 0;
      this.visible = false;
    }
  }
};
</script>

<style lang="scss">
.payment-selector-modal {
  .el-dialog {
    .el-dialog__title {
      font-family: Montserrat;
      font-weight: 700;
      color: $preto;
    }
  }

  .amount-input {
    h5 {
      text-align: center;
      margin: 1rem 0;
    }
    input {
      margin: 0 0 1.5rem 0;
      background: transparent;
      border: none;
      width: 100%;
      text-align: center;
      font-size: 38px !important;
      font-family: Montserrat;
      font-weight: 700;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      color: $laranja;
    }
  }
  .el-alert {
    margin-bottom: 1.5rem;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    .el-button {
      flex: 1;
    }
  }
}
</style>
