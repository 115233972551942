<template>
  <section class="previous-statement-wrapper">
    <div class="previous-title">
      <article>Fatura anterior</article>
      <div class="previous-statement-amount">
        <h5>RESTANTE FATURA ANTERIOR</h5>
        <h5
          style="font-family: 'Roboto Mono', monospace;"
        >{{ formatMoney(data.total_previous_statement_amount) }}</h5>
      </div>
      <payment-selector-list
        style="margin-top: 1rem;"
        :data="data.previous_payments"
        :hide-total="true"
      />
      <div class="previous-statement-balance">
        <h5>SALDO RESTANTE</h5>
        <h5 class="money">{{ handlePreviousStatementBalance() }}</h5>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import toMoney from "@/helpers/toMoney";

import PaymentSelectorList from "./PaymentSelectorList";

export default {
  components: {
    PaymentSelectorList
  },
  props: {
    data: {
      type: [Object, null],
      default: () => {}
    },
    future: {
      type: [Boolean, null],
      default: false
    }
  },
  data() {
    return {
      showPrevious: true
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatMoney(amount, removeIndicator) {
      if (removeIndicator) {
        return toMoney(amount)
          .replace("R$", "")
          .replace(/\s/g, "");
      }
      return toMoney(amount);
    },
    handlePreviousStatementBalance() {
      const { previous_condition } = this.data;
      if (previous_condition && previous_condition.length > 0) {
        const previousAmount = previous_condition.find(
          item => item.description === "Saldo fatura anterior"
        );
        if (previousAmount) {
          return this.formatMoney(previousAmount.amount);
        }
      }
      return this.formatMoney(0);
    }
  }
};
</script>

<style lang="scss">
.previous-statement-wrapper {
  background-color: #f6f6f6;
  padding: 12px 14px;
  position: relative;
  overflow: visible;
  .previous-title {
    width: 100%;
    position: relative;
    &:after,
    &:before {
      display: block;
      content: " ";
      width: 37.5%;
      height: 1px;
      background-color: $preto;
      opacity: 0.3;
      position: absolute;
      top: 10.3px;
      @media screen and (max-width: 768px) {
        width: 25%;
      }
    }
    &:after {
      right: 0;
    }
  }
  article {
    width: 25%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    font-family: Montserrat;
    color: $preto;
    @media screen and (max-width: 768px) {
      width: 50%;
    }
  }
  .previous-statement-amount {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    h5 {
      font-family: Montserrat;
      font-weight: 700;
      padding-right: 45.6px;
      font-size: 13px;
    }
  }
  .previous-statement-balance {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    h5 {
      margin-top: 0.5rem;
      font-family: Montserrat;
      font-weight: 700;
      font-size: 13px;
    }
    .money {
      color: $preto;
      margin-left: 8px;
      padding-right: 45.6px;
      text-align: right;
      font-family: "Roboto Mono", monospace;
      font-size: 13px;
    }
  }
  .payment-selector-list-item {
    padding-right: 45.2px;
  }
}
</style>
